var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#fff !important" } },
    [
      _c(
        "v-form",
        { ref: "checklistRenderForm" },
        [
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "mb-0 pb-0",
                  attrs: { cols: "12", lg: "4", sm: "6" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      readonly: "",
                      "hide-details": "",
                      outlined: "",
                      label: _vm.$t("message.checklist.templateName"),
                    },
                    model: {
                      value: _vm.templateName,
                      callback: function ($$v) {
                        _vm.templateName = $$v
                      },
                      expression: "templateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "mb-0 pb-0",
                  attrs: { cols: "12", lg: "4", sm: "6" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      label: _vm.$t("message.deviation.title"),
                    },
                    model: {
                      value: _vm.checklistTitle,
                      callback: function ($$v) {
                        _vm.checklistTitle = $$v
                      },
                      expression: "checklistTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "mb-0 pb-0",
                  attrs: { cols: "12", lg: "4", sm: "6" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      label: _vm.$t("message.checklist.report_header"),
                    },
                    model: {
                      value: _vm.report_header,
                      callback: function ($$v) {
                        _vm.report_header = $$v
                      },
                      expression: "report_header",
                    },
                  }),
                ],
                1
              ),
              _vm.can_send_email == 1
                ? _c(
                    "v-col",
                    {
                      staticClass: "mb-0 pb-0",
                      attrs: { cols: "12", lg: "4", sm: "6" },
                    },
                    [
                      _c("v-combobox", {
                        staticClass: "mb-3",
                        attrs: {
                          items: _vm.emailRecipients,
                          "search-input": _vm.search_email,
                          label: _vm.$t(
                            "message.checklist.checklist_recipients"
                          ),
                          "hide-selected": "",
                          multiple: "",
                          "persistent-hint": "",
                          outlined: "",
                          "small-chips": "",
                          "deletable-chips": "",
                          dense: "",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.search_email = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.search_email = $event
                          },
                        },
                        model: {
                          value: _vm.checklistRecipients,
                          callback: function ($$v) {
                            _vm.checklistRecipients = $$v
                          },
                          expression: "checklistRecipients",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.checklistProjectData
            ? _c(
                "v-row",
                _vm._l(_vm.checklistProjectData, function (projectdata, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      attrs: { cols: "12", lg: "3", md: "4", xs: "12" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: projectdata,
                          label: _vm.$t("message.checklist." + index),
                          "persistent-hint": "",
                          readonly: "",
                          dense: "",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  !_vm.usersListSet
                    ? _c(
                        "v-row",
                        { staticClass: "mx-0" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-progress-linear", {
                                attrs: {
                                  color: "primary accent-4",
                                  indeterminate: "",
                                  rounded: "",
                                  height: "6",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("form-render", {
                        key: _vm.reInit,
                        staticClass: "pa-1",
                        attrs: {
                          checklistId: _vm.checklistId,
                          formId: _vm.formId,
                          token: _vm.accessToken,
                          module: _vm.module,
                          projectChecklistFormData:
                            _vm.projectChecklistFormData,
                          tenantUsers: _vm.tenantUsers,
                          parentFormRef: _vm.$refs.checklistRenderForm,
                          selectedProjectId: _vm.selectedProjectId,
                          dataGridImportValue: _vm.dataGridImportValue,
                          checklistData: _vm.checklistData,
                          checklistTemplateData: _vm.checklistTemplateData,
                        },
                        on: {
                          back: _vm.backToList,
                          update: _vm.onSuccessHandler,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }