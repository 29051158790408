var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "checkListForm" },
        [
          _c(
            "v-container",
            { staticClass: "pa-1", attrs: { fluid: "" } },
            [
              _vm.aliasFieldsList.length < 1 && !_vm.loadingKeep
                ? _c("v-progress-linear", {
                    attrs: {
                      color: "primary accent-4",
                      indeterminate: "",
                      rounded: "",
                      height: "6",
                    },
                  })
                : _vm.aliasFieldsList.length
                ? _c("form-render", {
                    ref: "fieldsFormRef",
                    attrs: {
                      aliasFieldsList: _vm.aliasFieldsList,
                      fields: _vm.fieldsData,
                      checklistId: _vm.checklistId,
                      listOfDocuments: _vm.listOfDocuments,
                      dataGridImportValue: _vm.dataGridImportValue,
                    },
                    on: {
                      filterGrid: _vm.filterGrid,
                      filterDataGridValue: _vm.filterDataGridValue,
                      dragAndDrop: _vm.dragAndDrop,
                    },
                  })
                : _vm.aliasFieldsList.length === 0
                ? _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center font-weight-bold text-h6" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("message.common.no_fields_found"))
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.fromPanel
                ? _c("v-divider", { staticClass: "mt-3 mb-1" })
                : _vm._e(),
              !_vm.fromPanel
                ? _c(
                    "v-row",
                    { staticClass: "position_fixed" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { color: "success", loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.saveUpdateChecklistHandler(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "message.common." +
                                      (_vm.checklistId ? "update" : "save")
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { color: "info", loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.saveUpdateChecklistHandler(
                                    true,
                                    true
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "message.common." +
                                      (_vm.checklistId
                                        ? "updateAndClose"
                                        : "saveAndClose")
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { color: "primary" },
                              on: { click: _vm.closeHandler },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("message.common.backToList"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }